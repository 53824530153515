// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
  background: #ecede7;
  font-family: 'Roboto', sans-serif;
  color: black;
  font-size: 15px;
}

$vredemans_orange: #f17c21;
$dark_orange: #ff8c00;
$orange_hover: #ffa121;
$gray: #666666;
$breakpoint-lg-big: 1420px;

.full-width {
  display: block;
  width: 100%;
}

.header {
  background-color: white;
  background-image: url('../../../public/themes/frontend/assets/images/stripes-background-header.png');
  background-repeat: repeat-x;
  background-position: bottom center;
  border: 3px solid white;

  .logo {
    margin-top: 20px;
  }

  .bovag {
    float: right;
    margin-top: 70px;

    @media(max-width: $screen-xs-max){
      position: absolute;
      width: 90px;
      top: 13px;
      margin-top: 0;
      right: 20px;
    }
  }

  .btn-to-occasions{
    margin-top: 67px;
    @media(max-width: $screen-xs-max){
      margin-top: 10px;
      margin-bottom: 10px;
    }
    padding: 8px 10px 10px 14px;
    @media(max-width: $screen-xs-max){
      padding: 4px 5px 5px 7px;
    }
    background-color: black;
    color: white;
    float: left;
    border-radius: 7px;
    cursor: pointer;
    z-index:9;
    position: relative;

    .dot{
      background-color: $orange_hover;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      padding: 5px 7px;
      font-size: 13px;
      margin-left: 6px;
      text-align: center;
    }
  }

  .auto-header {
    @media(min-width: $screen-md-min){
      margin-top: 15px;
      padding-left: 80px;
      padding-right: 80px;
    }
    @media print {
      display:none;
    }
    .auto-header-absolute-wrapper img {
      margin-bottom: -75px;
    }
  }
}

.gray-bar {
  background: #ecede7;
  height: 90px;
  margin-top: 3px;
}

.mainmenu {
  display: block;
  width: 100%;
  background: $vredemans_orange;
  border-bottom: 3px solid #cccccc;
  @media(min-width: $screen-xs-max){
    margin-bottom: 0;
  }

  .navbar-toggle{
    background: $vredemans_orange;
    margin-right: 0;

    .icon-bar{
      background: white;
    }
  }
  .navbar-brand{
    color: white;
    margin-top: 5px;
  }
  ul {
    list-style: none;
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;

    @media(min-width: $screen-xs-max){
      text-align: center;
    }

    li {
      cursor: pointer;
      padding-top: 5px;
      padding-bottom: 5px;
      @media(min-width: $screen-xs-max) {
        display: inline-block;
        float: none;
      }

      &.active{
        @media(min-width: $screen-xs-max){
          background-image: url('../../../public/themes/frontend/assets/images/stripes-mainmenu-background.png');
          background-position: center;
          background-repeat: no-repeat;
        }
      }


      a {
        cursor: pointer;
        @media(max-width: $screen-xs-max){
          background-color: rgb(255, 140, 0);
          color: white;
          border: 1px solid #ffaf00;
        }
        @media(min-width: $screen-xs-max){
          background-color: $dark_orange;
          border: 1px solid #ffaf00;
        }
        padding: 5px 38px;
        text-transform: uppercase;
        font-size: 18px;
        display: block;
        color: white;

        @media(max-width: $screen-xs-max){
          padding-left: 10px;
          width: 100%;
        }
        @media(min-width: $screen-xs-max) and (max-width: $screen-md-max){
          padding: 5px 10px;
        }

        font-size: 20px;

        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max){
          font-size: 18px;
        }
        display: block;
        @media(max-width: $screen-xs-max){
          display: inline-block;
        }

        &:hover, &:focus{
          background: $vredemans_orange;
          color: white;
        }
      }
    }
  }
  .navbar-collapse{
    padding: 0;
  }
}

.occasion-tile {
  $padding: 5px;
  padding: $padding;
  background: white;
  display: block;
  float: left;
  border: 1px solid #e6e6e6;
  margin-bottom: 30px;
  @media(min-width: $screen-sm-min) AND (max-width: $screen-sm-max){
    min-height: 510px;
  }
  @media(min-width: $screen-md-min) AND (max-width: $screen-md-max){
    min-height: 475px;
  }
  @media(min-width: $screen-lg-min){
    min-height: 500px;
  }

  .image{
    float: left;
    position: relative;
    width: 100%;

    img{
      float: left;
      width: 100%;
    }
  }

  .kenteken {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: auto !important;
  }


  .occasion-details {
    float: left;
    clear: both;
    padding: 10px 10px 0 10px;

    .title {
      font-size: 20px;
      min-height: 70px;
    }

    ul.specs {
      list-style: none;
      margin: 0;
      padding: 0;
      float: left;
      min-height: 42px;

      li {
        float: left;
        padding: 3px;
        color: $gray;
        line-height: 15px;

        &:after {
          content: " \\ ";
          color: $vredemans_orange;
        }

        &:last-child{
          &:after {
            content: "";
          }
        }
      }
    }

    .price {
      font-size: 22px;
      color: $vredemans_orange;
    }
  }

  a.offer {
    color: $gray;
    top: 10px;
    position: relative;
    margin-left: 10px;
  }

  .nap-price {
    padding: 10px 10px;
  }

  .to-details {

    a {
      position: relative;
      background-color: $vredemans_orange;
      display: block;
      padding: 10px 20px;
      color: white;
      margin-right: -$padding;

      .skew {
        height: 100%;
        display: block;
        margin-left: -42px;
        top: 0;
        width: 30px;
        background-image: url('../../../public/themes/frontend/assets/images/skew-left.svg');
        position: absolute;
      }
    }
  }
}
.logolink{
  z-index: 1;
  position: relative;
}
.slick-slider{
  .occasion-tile{
    margin-bottom: 0;
  }
}
.black-bar{
  background-color: black;
  background-image: url('../../../public/themes/frontend/assets/images/stripes-bar.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 30px;
  color: white;
  padding: 10px 10px 10px 60px;
  display: block;
  margin: 20px 0;
  font-weight: bold;
  font-size: 21px;
  text-align: left;
  cursor: pointer;

  &:hover, &:focus{
    color: white;
  }
}

.stripes-bottom-3{
  background-image: url('../../../public/themes/frontend/assets/images/stripes-black-3perc.png');
  background-repeat: repeat-x;
  background-position: bottom;
}

h1{
  font-size: 25px;
  margin: 0;
  padding: 0;
}

.pane-title{
  padding: 10px;
}
h2{
  margin: 0;
  padding: 0;
  font-size: 21px;
}
.backgr-orange{
  background-color: $vredemans_orange;
}
.backgr-white{
  background-color: white;
}
.backgr-black{
  background-color: black
}
.pane-title.backgr-orange, .pane-title.backgr-black{
  color: white;
}


.pane-content{
  background-color: white;
  border: 1px solid #999999;
  border-top: none;
  margin-bottom: 20px;
  padding: 15px;
  background-position: 90% 20px;
  background-size: 45px;
  background-repeat: no-repeat;

  &.pane-content.background-icon{
    padding: 25px 100px 25px 25px;
  }
}
.background-icon.location-icon{
  background-image: url('../../../public/themes/frontend/assets/images/location-icon.svg');
}
.background-icon.clock-icon{
  background-image: url('../../../public/themes/frontend/assets/images/clock-icon.svg');
  background-size: 60px;
}
p{
  margin: 0 0 38px 0;
  padding: 0;

  &:last-of-type{
    margin: 0;
  }
}
.flexcolumns{
  display: flex;
  flex-directon: row;

  .flexcolumn-1{
    flex: 1;
  }
  .flexcolumn-2{
    flex: 2;
  }
  .centered{
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
.flex-row-sm{
  @media(min-width: $screen-sm-min){
    display: flex;
  }
}
.flex-wrap{
  flex-wrap: wrap;
}
.section-padding-top{
  padding-top: 20px;
}
.section-padding-bottom{
  padding-bottom: 20px;
}
.like-us-fb{
  color: white;
  padding: 20px 0 20px 60px;
  background-image: url('../../../public/themes/frontend/assets/images/icon-fb.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 35px;

  @media(min-width: $screen-md-min){
    top: 50px;
  }
  @media(max-width: $screen-md-min){
    top: 24px;
  }
  @media(max-width: $screen-xs-max){
    top: 0;
  }
  position: relative;
  @media(max-width: $screen-xs-max){
    display: inline-block;
  }
  &:hover{
    color: white;
  }
}
.logo-footer{
  @media(max-width: $screen-xs-max){
    margin-top: 30px;
  }
}
$filtermenu-width: 400px;

.filtermenu{
  $filtermenu-padding: 30px;
  width: $filtermenu-width;
  @media(max-width: 767px) {
    width: 100%;
  }
  height: 100%;
  position: fixed;
  display: block;
  z-index: 3;
  background: white;
  transition: 1s;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .close-filters{
    position: absolute;
    right: $filtermenu-padding;
    top: $filtermenu-padding;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: block;
    background-size: 100%;
    background-image: url('../../../public/themes/frontend/assets/images/close.svg');
  }

  .content{
    position: relative;
    padding: $filtermenu-padding;

    .filterrow{
      border: 1px solid #dddddd;
      background-color: #f8f8f8;
      padding: 5px 15px 10px 15px;
      border-radius: 10px;
      margin: 20px 0;
    }

    .filtername{
      font-size: 15px;
      font-weight: bold;
      clear: both;
      margin: 0 0 5px 0;
    }

    ul{
      list-style: none;
      padding: 0;
      margin: 0 0 15px 0;

      &.short{
        li:nth-child(n+5){
          display: none;
        }
        li.toggle-short{
          display: block;
        }
      }

      li{
        margin: 0;
        padding: 0;
        color: black;

        a{
          color: $vredemans_orange;
          padding-left: 20px;
          background-image: url('../../../public/themes/frontend/assets/images/arrow-right.svg');
          background-repeat: no-repeat;
          background-size: 15px;
          background-position: left center;
        }
        &.toggle-short{
          cursor: pointer;
          display: block;
          background: #ecede7;
          padding: 5px 5px 5px 10px;
          margin-top: 10px;
        }
      }
      li.current_filter a{
        color: gray;
        background-image: none;
        padding: 5px 10px;
        background-color: #f8f8f8;
        border: 1px solid #dddddd;
        border-radius: 10px;
        margin-top: 2px;
        margin-bottom: 12px;

        .close-x{
          margin-left: 10px;
        }
      }
    }
  }
}

.filtermenu.closed{
  left: -$filtermenu-width;
  @media(max-width: 767px) {
    left: -100%;
  }
}
.overlay{
  background: black;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: 1;
  transition: 0.5s;
}
.overlay.closed{
  opacity: 0;
  transition: 0.5s;
}
ul.slick-slider{
  padding: 0 !important;
  margin: 0;
  width: 100%;
  float: left;
  position: relative;
  overflow: hidden;

  li.slick-slide{
    margin: 0 15px;
    float: left;
    list-style: none;
  }
}

.slick-arrow{
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  top: 150px;
  border-radius: 50px;
  background-color: white;
  background-position: center center;
  @media(max-width: $screen-xs-max){
    top: 100px;
  }
  cursor: pointer;
  background-repeat: no-repeat;
  z-index: 2;
  background-size: 12px;

  @media(max-width: $breakpoint-lg-big){
    background-color: white;
    background-position: center !important;
    background-size: 15px;
  }

  &.left{
    left: 30px;
    @media(max-width: $breakpoint-lg-big){
      left: 0;
    }
    background-image: url('../../../public/themes/frontend/assets/images/arrow-big-left.svg');
  }

  &.right{
    right: 30px;
    @media(max-width: $breakpoint-lg-big){
      right: 0;
    }

    background-image: url('../../../public/themes/frontend/assets/images/arrow-big-right.svg');
  }
}
@media(max-width: $screen-xs-max) {
  .mobile-margin-top {
    margin-top: 20px;
  }
  .text-center-xs {
    text-align: center;
  }
}
.imagethumb{
  margin-bottom: 25px;
}
.back-to-overview{
  float: left;
  color: black;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
  @media print {
    display:none;
  }
}
.btn-sendform{
  background: #08D233;
  color: white;
  font-weight: bold;
  @media print {
    display: none;
  }

  &:hover{
    color: white;
    background: #08b632;
  }
}
.alert-succes{
  background-color: #08D233;
  border-color: #08D233;
  color: white;
  margin-top: 30px;
}
.alert-danger{
  background-color: #cf1d19;
  border-color: #cf1d19;
  color: white;
}
.teamrow{
  p{
    margin: 0 0 10px 0;
  }
}


.teamrow .content-pane{

  h2{
    @media(min-width: $screen-md-min) AND (max-width: $screen-md-max) {
      font-size: 12px;
    }
    @media(min-width: $screen-lg-min){
      font-size: 15px;
    }
  }
}

.google-maps{
  min-height: 360px;
}
.pagination > li > a{
  color: $vredemans_orange;
}
.pagination > .active > span{
  color: white;
  background-color: $vredemans_orange;
  border-color: $vredemans_orange;

  &:hover{
    border-color: $vredemans_orange;
    background-color: #C86521;
  }
}

.hvr-shutter-out-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  background: #e1e1e1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-shutter-out-vertical:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $orange_hover;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-shutter-out-vertical:hover, .hvr-shutter-out-vertical:focus, .hvr-shutter-out-vertical:active {
  color: white;
}
.hvr-shutter-out-vertical:hover:before, .hvr-shutter-out-vertical:focus:before, .hvr-shutter-out-vertical:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
/* Sweep To Right */
.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $orange_hover;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
  color: white;
}
.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
#voorraadFrame{
  border: 1px solid #e0e0e0;
}
.pagecontent{
  padding-top: 30px;
}
.buttons-homepage{
  margin-top: 40px;
}
.voorraad-button a{
  background-image: url('../images/beeldmerk.png');
  background-repeat: no-repeat;
  background-size: 60px;
  display: block;
  height: 70px;
  width: 100%;
  font-size: 20px;
  color: black;
  padding-left: 70px;
  line-height: 24px;
  font-weight: bold;
  padding-top: 4px;
  @media(max-width: $screen-sm-max){
    margin-top: 28px;
  }
  @media(max-width: $screen-xs-max){
    padding-top: 18px;
  }
  @media(max-width: 500px){
    font-size: 17px;
  }
}
.likeusonfacebook{


  @media(min-width: $screen-lg-min){
    margin-top: 41px;
  }
  @media(min-width: $screen-md-min) AND (max-width: $screen-md-max){
    margin-top: 20px;
  }
  @media(min-width: $screen-sm-min) AND (max-width: $screen-sm-max){
    margin-top: 11px;
  }
  @media(max-width: $screen-xs-max){
    margin-top: 30px;
  }
}

/* INFO ITEMS */

.info-items ul{
  list-style: none;
  margin: 0;
  padding: 0;
  @media print {
    display: none;
  }

}
.info-items ul li{
  display: block;
  margin-bottom: 10px;
}
.info-items ul li:last-child{
  margin-bottom: 0px;
}
.info-items ul li a{
  display: block;
  background-color: white;
  border: 1px solid #d1d1d1;
  color: black;
}
.info-items ul li a .icon{
  padding: 20px;
  display: block;
  width: 40px;
  float: left;
}
.info-items ul li a .tekst{
  float: left;
  margin-left: 10px;
  margin-top: 11px;
}
.info-items .icon{
  background-repeat: no-repeat;
  background-position: center;
}
.icon-deelopfb{
  background-color: #405d9b;
  background-image: url('https://vredemans.nl/themes/frontend/assets/images/icon-deelopfb.png');
}
.icon-deeloptw{
  background-color: #55acee;
  background-image:url('https://vredemans.nl/themes/frontend/assets/images/icon-deeloptw.png');
}
.icon-deelperemail{
  background-color: #818181;
  background-image:url('https://vredemans.nl/themes/frontend/assets/images/icon-deelperemail.png');
}
.icon-print{
  background-color: #003374;
  background-image:url('https://vredemans.nl/themes/frontend/assets/images/icon-print.png');
}
.interested-form{
  @media print {
    display: none;
  }
}
.mainimage{
  @media print {
    display:none !important;
  }
}

.share-via-email{

  h1{
    margin: 30px 0 10px 0;
  }
  p{
    margin-bottom: 20px;
  }
  .alert{
    margin-top: 30px;
  }
}
.link-privacypolicy{
  color: black;
  margin-top: 47px;
  float: right;
  @media(min-width: $screen-md-min) AND (max-width: $screen-md-max){
    margin-top: 22px ;
  }
  @media(min-width: $screen-sm-min) AND (max-width: $screen-sm-max){
    margin-top: 12px ;
  }
}

form {
  input[type="text"], input[type="date"], input[type="email"], textarea {
    width: 100%;
  }
  h2 {
    padding-bottom:20px;
    padding-top:30px;
  }
  label input {
    margin-right:5px !important;
  }
}
.d-block{
  display:block;
  font-weight: normal;
}